<template>
  <!-- 实验室详情 -->
  <div class="co_contain">
    <div class="contain_up">
      <div class="main-title">
        <div class="title-left">
          <el-image class="elimage" :src="roominfo.lab_room_image" />
          <!-- <el-image class="elimage" :src="randomimage()" /> -->
        </div>
        <div class="title-right">
          <div class="title-text">{{ roominfo.lab_name }} <span v-if="roominfo.lab_room_sn">({{roominfo.lab_room_sn}})</span></div>
          <div class="position-icon">
            <div class="left">

              <div class="item">
                <div class="uptext">{{ roominfo.building_name }}</div>
                <div class="downtext">实验室地点</div>
              </div>
              <!-- <div class="item">
                <div class="uptext">{{ roominfo.lab_room_sn }}</div>
                <div class="downtext">房间号</div>
              </div> -->
              <div class="item">
                <div class="uptext">{{ roominfo.station_num }}</div>
                <div class="downtext">实验位(人)</div>
              </div>

              <div class="item">
                <div class="uptext">{{ roominfo.area_size }}</div>
                <div class="downtext">面积(㎡)</div>
              </div>
            </div>
          </div>
          <div class="yuyue_bt">
            <el-button type="primary" class="bt_text" @click="toroomreservation()">实验用房预约</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="contain_down">
      <div class="bottom-contain">
        <div class="left-bts">
          <div :class="activebtid === index ? 'bt btactive' : 'bt'" v-for="(item, index) in btlist" :key="index" @click="clickbt(index, item)"
            v-clickDown="index">
            <template v-if="activebtid === index">
              <el-image class="bt-icon" :src="item.icon2" />
              <div class="bt-text bt-text_active">{{ item.name }}</div>
            </template>
            <template v-else>
              <el-image class="bt-icon" :src="item.icon" />
              <div class="bt-text">{{ item.name }}</div>
            </template>
          </div>
        </div>

        <div class="right-contain">
          <component :is="componentId" ref="mycomponent"></component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getToken, getIdentifierToken } from "@/utils/auth";
import { getOpenInfo } from "@/api/openroom";
import Housingarrangement from "./components/housingarrangement.vue";
import Roomintroduction from "./components/roomintroduction.vue";
import Teampersonnel from "./components/teampersonnel.vue";
import Equipment from "./components/equipment.vue";
import Experimentdisplay from "./components/experimentdisplay.vue";
export default {
  name: "courselearning",
  components: {
    Roomintroduction,
    Housingarrangement,
    Teampersonnel,
    Equipment,
    Experimentdisplay,
  },
  directives: {
    clickDown: {
      inserted(el, binding, index) {
        if (binding.value === 0) {
          el.click();
        }
      },
    },
  },
  data() {
    return {
      token: getToken() ? true : false,
      activebtid: 0,
      componentId: "Roomintroduction",
      roominfo: "",
      roomid: "",
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/图层 57.png"),
      icon2: require("@/assets/coursedetails/43443.png"),
      icon3: require("@/assets/coursedetails/图层 54.png"),
      icon4: require("@/assets/coursedetails/图层 53.png"),
      icon5: require("@/assets/coursedetails/图层 55.png"),
      icon6: require("@/assets/coursedetails/图层 59.png"),

      btlist: [
        {
          name: "实验用房介绍",
          icon: require("@/assets/newchange/10.png"),
          icon2: require("@/assets/newchange/10.1.png"),
          component: "Roomintroduction",
        },
        {
          name: "队伍人员",
          icon: require("@/assets/newchange/2.png"),
          icon2: require("@/assets/newchange/2.1.png"),
          component: "Teampersonnel",
        },
        {
          name: "实验室设备",
          icon: require("@/assets/newchange/12.png"),
          icon2: require("@/assets/newchange/12.1.png"),
          component: "Equipment",
        },
        // {
        //   name: "优秀实验展示",
        //   icon: require("@/assets/newchange/13.png"),
        //   icon2: require("@/assets/newchange/13.1.png"),
        //   component: "Experimentdisplay",
        // },
        {
          name: "用房安排",
          icon: require("@/assets/newchange/14.png"),
          icon2: require("@/assets/newchange/14.1.png"),
          component: "Housingarrangement",
        },
      ],

      image01: require("@/assets/defaultimages/1首页--1实验用房使用排行_23.jpg"),
      image02: require("@/assets/defaultimages/1首页--1实验用房使用排行_25.jpg"),
      image03: require("@/assets/defaultimages/1首页--1实验用房使用排行_36.jpg"),
      image04: require("@/assets/defaultimages/1首页--1实验用房使用排行_60.jpg"),
      image05: require("@/assets/defaultimages/1首页--1实验用房使用排行_57.jpg"),
      image06: require("@/assets/defaultimages/1首页--1实验用房使用排行_41.jpg"),
      image07: require("@/assets/defaultimages/1首页--1实验用房使用排行_44.jpg"),
      image08: require("@/assets/defaultimages/1首页--1实验用房使用排行_03.jpg"),
    };
  },

  created() {
    this.roomid = this.$route.query.id;
    this.getinfo();
  },
  methods: {
    randomimage() {
      let items = [
        this.image01,
        this.image02,
        this.image03,
        this.image04,
        this.image05,
        this.image06,
        this.image07,
        this.image08,
      ];
      let item = items[Math.floor(Math.random() * items.length)];
      return item;
    },
    clickbt(index, item) {
      this.activebtid = index;
      this.componentId = item.component;
      this.$nextTick(() => {
        this.$refs.mycomponent.initdata(this.roominfo);
      });
    },
    toroomreservation() {
      if (this.token) {
        if (getIdentifierToken() == 3) {
          this.$confirm("该功能仅对校内人员开放!", "提示", {
            confirmButtonText: "知道了",
            showCancelButton: false,
            type: "warning",
          })
            .then(() => { })
            .catch(() => { });
        } else {
          let obj = JSON.stringify(this.roominfo);
          console.log(this.roominfo);
          this.$router.push({
            path: "/home/openroom/roomreservation",
            query: {
              id: this.roomid,
              tip: this.roominfo.tips,
            },
          });
        }
      } else {
        this.$confirm("请先登录!", "登录提示", {
          confirmButtonText: "去登录",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
          })
          .catch(() => {

          });
      }
    },

    getinfo() {
      let params = { id: parseInt(this.roomid) };
      getOpenInfo(params).then((response) => {
        this.roominfo = response.data;
        this.$nextTick(() => {
          this.$refs.mycomponent.initdata(this.roominfo);
        });
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.co_contain {
  margin-bottom: 20px;
  .contain_up {
    background: url(~@/assets/newchange/detail_bg_1.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 430px;
    .main-title {
      width: 1200px;
      margin-top: 10px;
      margin-bottom: 20px;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .title-left {
        .elimage {
          width: 500px;
          height: 315px;
        }
      }
      .title-right {
        margin-left: 40px;
        flex-grow: 1;
        flex-shrink: 1;
        height: 315px;
        .title-text {
          margin-top: 10px;
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
        }
        .intro-contain {
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          align-content: center;
          .item {
            margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-content: flex-end;
            .nametitle {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .name {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
        }
        .position-icon {
          margin-top: 100px;
          padding-left: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .item {
              margin-right: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .uptext {
                font-size: 30px;
                font-weight: 500;
                color: #ffffff;
                white-space: nowrap;
              }
              .downtext {
                line-height: 30px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                white-space: nowrap;
              }
            }
          }
        }
        .yuyue_bt {
          margin-top: 60px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .bt_text {
            // width: 120px;
            height: 40px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 16px;
            font-weight: bold;
            color: #ffffff;
          }
        }
      }
    }
  }
  .contain_down {
    // min-height: 700px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: url(~@/assets/newchange/detail_bg_2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-bottom: 20px;
    .bottom-contain {
      margin-top: 30px;
      width: 1200px;
      display: flex;
      justify-content: flex-start;
      // align-items: flex-start;
      align-items: stretch;
      margin-bottom: 20px;
      .left-bts {
        min-width: 220px;
       // height: 530px;

        background: #ffffff;
        padding-top: 37px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .bt {
          width: 140px;
          height: 40px;
          padding-left: 10px;
          padding-right: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          user-select: none;
          cursor: pointer;
          margin-bottom: 20px;
          &:hover {
            background: #3d84ff54;
            border-radius: 5px;
          }
          .bt-icon {
            margin-right: 10px;
          }
          .elimage {
            margin-right: 10px;
          }
          .bt-text {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
          .bt-text_active {
            color: #ffffff;
          }
        }
        .btactive {
          background: #3d84ff;
          border-radius: 5px;
        }
      }

      .right-contain {
        margin-left: 30px;
        background: #ffffff;
        flex: 1;
        min-height: 500px;
        padding: 10px;
      }
    }
  }
}
</style>
