<template>
  <!-- 用房安排 -->
  <div class="main-contain">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input class="elinput" v-model="queryParams.keyword" placeholder="请输入预约人姓名">
            <!-- <i class="el-icon-search el-icon_search" slot="suffix"> </i> -->
          </el-input>
        </div>
        <div class="left2">
          <div class="text">审核状态:</div>
          <el-select class="elselect" v-model="queryParams.status" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="rightcontain">
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="info" @click="reset">重置</el-button>
        <!-- <div class="bt " @click="reset()">重置</div>
        <div class="bt bt1" @click="search">查询</div> -->
      </div>
    </div>
    <div class="contain">
      <el-table :data="tableData">
        <el-table-column label="预约人" prop="user_name" align="center">
        </el-table-column>

        <el-table-column label="预约时间段" min-width="200px" align="center">
          <template slot-scope="scope">
            {{ scope.row.start_time + "至" + scope.row.end_time }}
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="审核状态">
          <template slot-scope="scope">
            <div class="state">{{ scope.row.status }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { openroombookgetList } from "@/api/openroombook";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      tableData: [],
      total: 0,
      options: [
        {
          value: 0,
          label: "审核中",
        },
        {
          value: 1,
          label: "已取消预约",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
      queryParams: {
        keyword: "",
        status: "",
        open_room_id: "",
        page: 1,
        per_page: 10,
      },
      open_room_id: ''
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    initdata(obj) {
      // debugger
      this.queryParams.open_room_id = obj.id;
      this.getdata();
      this.open_room_id = obj.id
    },
    search() {
      this.getdata();
    },
    reset() {
      
      this.queryParams = {
        keyword: "",
        status: "",
        open_room_id: this.open_room_id,
        page: 1,
        per_page: 10,
      };
      this.getdata();
    },
    pageChange(val) {
      this.queryParams.page = val
      this.getdata();
    },
    getdata() {
      this.tableData = [];
      openroombookgetList(this.queryParams)
        .then((response) => {
          this.total = response.data.total;
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              switch (item.status) {
                case 0:
                  item.status = "待审核";
                  break;
                case 1:
                  item.status = "取消预约";
                  break;
                case 2:
                  item.status = "通过";
                  break;
                case 3:
                  item.status = "驳回";
                  break;
              }
              this.tableData.push({
                user_name: item.user_name,
                start_time: dayjs
                  .unix(item.start_time)
                  .format("YYYY-MM-DD HH:mm:ss"),
                end_time: dayjs
                  .unix(item.end_time)
                  .format("YYYY-MM-DD HH:mm:ss"),
                created_at: dayjs
                  .unix(item.created_at)
                  .format("YYYY-MM-DD HH:mm:ss"),
                status: item.status,
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>

.main-contain {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;

        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-left: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }
  .contain {
    .table-head-op {
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        user-select: none;
        white-space: nowrap;
      }
      .elselect {
        margin-left: 10px;
      }
    }
    .state {
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      // cursor: pointer;
    }
    .elpagination {
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
