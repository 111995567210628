<template>
  <!-- 队伍人员 -->
  <div class="main-contain">
    <div class="contain">
      <el-table
        :data="tableData"
        style="width: 100%"
        
      >
        <el-table-column label="姓名" prop="name"> </el-table-column>
        <el-table-column label="职称" prop="job_name"> </el-table-column>
        <el-table-column label="所属院系" prop="college_name">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      tableData: [],
      intro: "",
    };
  },
  methods: {
    initdata(obj) {
      if(obj.manager_list)
      this.tableData= obj.manager_list.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  position: relative;
  margin-top: 20px;
  .contain {
    position: absolute;
    width: 100%;
    height: 100%;
    .table-head-op {
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        user-select: none;
        white-space: nowrap;
      }
      .elselect {
        margin-left: 10px;
      }
    }
    .state {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      cursor: pointer;
    }
  }
}
</style>
