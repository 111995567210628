<template>
  <!-- 优秀实验展示 -->
  <div class="main-contain">
    <video width="300" height="200" controls :src="videourl" :poster="posterimg"/>
  </div>
</template>

<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      // videourl:[],
    };
  },
  methods: {
    // initdata(obj) {},
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 20px;
  //   border: 1px solid red;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 400px;
  overflow-y: auto;
  .elimage {
    margin-top: 10px;
    margin-right: 50px;
    width: 233px;
    height: 158px;
    border-radius: 4px;
  }
}
</style>
