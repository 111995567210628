<template>
  <!-- 实验室设备 -->
  <div class="main-contain">
    <div class="contain">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="设备名称" prop="name"> </el-table-column>
        <el-table-column label="主要应用" prop="mainuse"> </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :page-size="10" :current-page="queryParams.page"
          :total="total" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getEquipmentList } from "@/api/openroom";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        lab_id: '',
        page: 1,
        per_page: 10
      }
    };
  },
  methods: {
    initdata(obj) {
      this.queryParams.lab_id = obj.lab_id
      localStorage.setItem('lab_id', obj.lab_id)
      getEquipmentList(this.queryParams).then((response) => {
        if (response.code === 0){
          this.total = response.data.total;
          this.tableData = response.data.data;
        }
      });
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val
      var lab_id = localStorage.getItem('lab_id')
      this.queryParams.lab_id = lab_id
      getEquipmentList(this.queryParams).then((response) => {
        if (response.code === 0) {
          this.total = response.data.total;
          this.tableData = response.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  .contain {
    width: 100%;
    height: 100%;
    .table-head-op {
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        user-select: none;
        white-space: nowrap;
      }
      .elselect {
        margin-left: 10px;
      }
    }
    .state {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      cursor: pointer;
    }
    .elpagination {
      margin: 40px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
